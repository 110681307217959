import { Box, Typography } from "@mui/material"

//{icon,name,usage,description}

export const TechnologiesCards = ({data}) =>{
    return(
        <>
        <Box className='nvt-home-technologies-card'>
            <Box className='nvt-technologies-icon' >
            {data.icons}
            </Box>
            <Box className='nvt-technologies-body-card'>
                <Typography className='nvt-technologies-title' variant="h5">{data.title}</Typography>
                <Typography className='nvt-technologies-description'  variant="body2">{data.description}</Typography>
            </Box>
        </Box>
        </>
    )
}