import { Box, Typography } from "@mui/material"
import './styles.css'
import { AdvicesClient } from "./AdvicesClient"
export const DivAdvices = () => {
    return (
        <Box component='section' className="nvt-advices-container">
            <Box className='container'>
                <Typography variant='h4' className="nvt-title" data-aos='fade-up'>Ce que nos clients pense de nous</Typography>
                <Box className='row nvt-advice-margin-top'>
                    <Box className='col-12 col-md-4 col-lg-4'
                        data-aos='fade-up'
                        >
                        <AdvicesClient />
                    </Box>
                    <Box className='col-12 col-md-4 col-lg-4'
                        data-aos='fade-up'
                        data-aos-duration='400'>
                        <AdvicesClient />
                    </Box>
                    <Box className='col-12 col-md-4 col-lg-4'
                        data-aos='fade-up'
                        data-aos-duration='800'>
                        <AdvicesClient />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}