import { Box, Typography } from "@mui/material"

import google from './../../../Utils/Images/google.svg'
export const AdvicesClient = () => {
    return (
        <Box className='nvt-home-advice-card'>
            <Box className='nvt-home-advice-card-googl-icon'>
                <img src={google} />
            </Box>
            <Box>
                <Typography className="nvt-advice-quote">"Les agences web proposent généralement une variété de services pour la création."  </Typography>
            </Box>
            <Box className='nvt-home-advice-card-body'>
                <Box className='nvt-home-advice-card-profile'></Box>
                <Box>
                    <Typography variant="body1" className="nvt-home-advice-card-status">CEO of Hope Mobile</Typography>
                    <Typography variant="subtitle1" className="nvt-home-advice-card-name">Tsiory Razaka</Typography>
                </Box>
            </Box>
        </Box>
    )
}