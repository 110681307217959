import './styles.css'

export const InfiniteAnimation = () => {
    return (
        <>
            <div className="stock-ticker">
                <ul>
                    <li className="minus">
                        <span className="company">Creative</span>
                        <span className="price">Innover</span>
                        <span className="change">Design</span>
                    </li>
                    <li className="plus">
                        <span className="company">Creative</span>
                        <span className="price">Innover</span>
                        <span className="change">Design</span>
                    </li>
                    <li className="plus">
                        <span className="company">Creative</span>
                        <span className="price">Innover</span>
                        <span className="change">Design</span>
                    </li>
                    <li className="minus">
                        <span className="company">Creative</span>
                        <span className="price">Innover</span>
                        <span className="change">Design</span>
                    </li>
                    <li className="plus">
                        <span className="company">Creative</span>
                        <span className="price">Innover</span>
                        <span className="change">Design</span>
                    </li>
                    <li className="minus">
                        <span className="company">Creative</span>
                        <span className="price">Innover</span>
                        <span className="change">Design</span>
                    </li>
                    <li className="plus">
                        <span className="company">Creative</span>
                        <span className="price">Innover</span>
                        <span className="change">Design</span>
                    </li>
                    <li className="minus">
                        <span className="company">Creative</span>
                        <span className="price">Innover</span>
                        <span className="change">Design</span>
                    </li>
                </ul>

                <ul aria-hidden="true">
                    <li className="minus">
                        <span className="company">Creative</span>
                        <span className="price">Innover</span>
                        <span className="change">Design</span>
                    </li>
                    <li className="plus">
                        <span className="company">Creative</span>
                        <span className="price">Innover</span>
                        <span className="change">Design</span>
                    </li>
                    <li className="plus">
                        <span className="company">Creative</span>
                        <span className="price">Innover</span>
                        <span className="change">Design</span>
                    </li>
                    <li className="minus">
                        <span className="company">Creative</span>
                        <span className="price">Innover</span>
                        <span className="change">Design</span>
                    </li>
                    <li className="plus">
                        <span className="company">Creative</span>
                        <span className="price">Innover</span>
                        <span className="change">Design</span>
                    </li>
                    <li className="minus">
                        <span className="company">Creative</span>
                        <span className="price">Innover</span>
                        <span className="change">Design</span>
                    </li>
                    <li className="plus">
                        <span className="company">Creative</span>
                        <span className="price">Innover</span>
                        <span className="change">Design</span>
                    </li>
                    <li className="minus">
                        <span className="company">Creative</span>
                        <span className="price">Innover</span>
                        <span className="change">Design</span>
                    </li>
                </ul>
            </div>

        </>
    )
}