import { Typography, Box } from "@mui/material"
import './styles.css'
import { Card } from "./Card"
import { useContext } from "react"
import { DataContext } from "../../Context/DataContext"

export const Advantages = () => {


    const {home}= useContext(DataContext)

    return (
        <Box component='section' className="container nvt-advantages-section">
            <Typography variant='h4' className="nvt-title" data-aos='fade-up'>Advantages</Typography>
            <Box className='row nvt-card-advantages-container'
                >
                {
                    home.avantages.map((item, key) => (
                        <Box className='col-12 col-lg-6 col-md-6 mt-4' key={key}>
                            <Card paire={key} data={item} />
                        </Box>
                    ))
                }
            </Box>
        </Box>
    )
}