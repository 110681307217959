import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../Images/novetech.png'


import './styles.css'
import { NavLink, Outlet } from 'react-router-dom';
import { Footer } from '../../Components/Footer/Footer';

export const Navigation = () => {
    return (
        <>
            <header>
                <Navbar expand="lg" className='container'>
                    <Container fluid className='nvt-flex'>
                        <Navbar.Brand>
                            <NavLink to="/">
                                <img src={logo} alt='logo de novatech' width={150} />
                            </NavLink>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll">
                            <Nav>
                                <ul className='nvt-links'>
                                    <li className='nvt-parent-menu' ><NavLink to="https://www.facebook.com" target='_blank'>Linkedin</NavLink></li>
                                    <li><NavLink to="/nos-projets" target='_blank'>Facebook</NavLink></li>
                                    <li><NavLink to="mailto:arimanana@gmail.com">Demander un devis</NavLink></li>
                                </ul>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>   
            </header>
            <main className='nvt-body-main'>
                <Outlet/>
            </main>
            <Footer/>
        </>
    );
}

