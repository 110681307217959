import './styles.css'
import { Box, Button, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react';
import { AiFillMail } from 'react-icons/ai';

export const HeadSection = () => {
    const el = useRef()
    let [scroll,setScroll]=useState(0)
        let p = 0
    useEffect(()=>{

        window.addEventListener('scroll',(e)=>{
            setScroll(e.srcElement.defaultView.pageYOffset)
        })

    },[])

    return (
        <>
            <Box className='nvt-baniere' component='section' ref={el} style={{backgroundPositionY: `${Math.ceil(scroll/5)}px`}}>
                <Box className='nvt-bloc-header'>
                    <Typography
                        variant='h1'
                        className='nvt-title-variant-h1'
                        gutterBottom='20'
                        data-aos='fade-up'
                        data-aos-duration='500'
                    >Agence web a Madagascar</Typography>
                    <Box
                        className='nvt-paragraphe-header'
                        data-aos='fade-up'
                        data-aos-duration='600'
                    >
                        <Typography
                            variant='body1'
                            gutterBottom='20'
                        >Notre équipe d'experts propose des solutions personnalisées en création de sites web, développement mobile, SEO et marketing digital pour atteindre vos objectifs.
                        </Typography>
                        <Button
                            className='nvt-button-hader'
                            variant='contained'
                            disableElevation
                            startIcon ={<AiFillMail/>}
                        >Parlons de votre projet.
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    )
}