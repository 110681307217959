import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { Box,Typography } from '@mui/material';
import './styles.css'
import { DataContext } from '../../../Context/DataContext';
import { useContext } from 'react';

export const Splider = (data) => {

    const { home } = useContext(DataContext)

  

    const splideOptions = {
        autoplay: true,
        interval: 4000,
        type: 'loop',
        perPage: 2,
        autoplay: true,
        autoScroll: {
            speed: 8,
        },
    };

    return (
        <>
            <Box component='section' className='nvt-splider-for-services container'>

                <Box className='nvt-splider-for-services-explain'
                    data-aos='fade-up'
                    data-aos-duration='1000'
                >
                    <Typography
                        variant='h3'
                        className='nvt-title-variant-h2'
                    >
                        Nos prestations destinées aux entreprises
                    </Typography>
                    <Typography
                        variant='body1'
                    >
                        Notre offre comprend une variété de solutions sur mesure, telles que la gestion de projet, la création de sites web sur mesure, le développement de boutiques en ligne et la création d'une application mobile.<br/><br/> Que vous soyez un individu, une start-up en plein essor ou une entreprise renommée, notre équipe chevronnée est disponible pour vous offrir les outils et l'expertise indispensables pour atteindre vos objectifs. 
                    </Typography>
                </Box>
                <Splide options={splideOptions}
                    data-aos='fade-left'
                    data-aos-duration='1000'
                >

                    {
                        home.splider.map((item, key) => (
                            <SplideSlide className='nvt-splider-img'
                                key={key}
                                // style={{ backgroundImage: `linear-gradient(#05000ea7,#05000ef4),url(${item.src_img})` }}
                                >
                                <Box className='nvt-box-inner-splider'>
                                    <Typography variant='h4'>{item.title}</Typography>
                                    <Box className='nvt-splider-content'>
                                        <Typography variant='body2'>{item.content}</Typography>
                                    </Box>
                                </Box>
                            </SplideSlide>
                        ))
                    }

                </Splide>
            </Box></>
    );
};


