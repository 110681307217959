import { Box, Grid, Typography } from "@mui/material"

export const Card = ({ paire, data }) => {



    return (

        <>
            <Grid direction='row'
                container
                className='nvt-card-advantages'
                spacing={1}
                data-aos={(paire % 2 === 1) ? 'fade-left' : 'fade-right'}
                data-aos-duration='200'
            >
                <Grid item md={4} className="nvt-border-right">
                    <img />
                </Grid>
                <Grid item md={8}>
                    <Typography className="nvt-avantages-title" variant='h6'>{data.title}</Typography>
                    <>
                        {data.content.split('.').map((item,k) =>(
                            <Typography key={k} variant='body2'>{item}</Typography>
                        ))}
                    </>
                </Grid>

            </Grid>
        </>

    )
}