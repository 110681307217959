import './styles.css'
import { HeadSection } from "../../Components/head/headSection";
import { Splider } from '../../Components/cards/Slipders/Splider';
import { FaqComponent } from '../../Components/Faq/FaqComponent';
import { InfiniteAnimation } from '../../Components/infiniteScroll/infinite';
import { Advantages } from '../../Components/advantages/Advantages';
import { TechnoParent } from '../../Components/cards/techno/DivTehcno';
import { AdvicesClient } from '../../Components/cards/google/AdvicesClient';
import { DivAdvices } from '../../Components/cards/google/DivAdvices';

export const Home = () => {
   
    return (
        <>
            <HeadSection/>
            <InfiniteAnimation/>
            <Splider/> 
            <Advantages/>
            <TechnoParent/>
            <DivAdvices/>
            <FaqComponent/>
        </>
    )
}