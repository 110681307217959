import { Box, Typography } from "@mui/material"
import './styles.css'
import { TechnologiesCards } from "./technologiesCards"
import { useContext } from "react"
import { DataContext } from "../../../Context/DataContext"

export const TechnoParent = () => {
    const { home } = useContext(DataContext)

  
    return (
        <Box component='section' className="nvt-technologie-section container">
            <Typography variant='h4' className="nvt-title" data-aos='fade-up'>Nos technologies</Typography>
            <Box className='row'>
                {
                    home.technologies.content.map((item, key) => (
                        <Box className='col-4 col-lg-4 col-md-4 mt-2'
                            key={key}
                            data-aos='fade-up'
                            data-aos-duration='400'>
                            <TechnologiesCards data={item} />
                        </Box>
                    ))
                }
            </Box>
        </Box>
    )
}