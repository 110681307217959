import { useEffect, useState} from 'react';
import './App.css';
import { Home } from './Pages/Home/Home';
import { Navigation } from './Utils/Menu/Navigation';
import { Route, RouterProvider, Routes, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Lenis from 'lenis';
import { DataContext } from './Context/DataContext';
import { dbcontent } from './Database/db-content';


const router = createBrowserRouter(

  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Navigation />}>
        <Route index element={<Home />} />
      </Route>
    </Route>
  )
)


function App() {

  useEffect(() => {
    AOS.init({

    });

    const lenis = new Lenis({
      lerp:0.25,
      wheelMultiplier:1,
    })
    lenis.on('scroll', (e) => {

      AOS.refreshHard();
    })
    function raf(time) {
      lenis.raf(time)
      requestAnimationFrame(raf)
    }
    requestAnimationFrame(raf)

  }, [])
  

  return (

    <>
      <DataContext.Provider value={dbcontent}>
        <RouterProvider router={router} />
      </DataContext.Provider>
    </>
  );
}

export default App;
