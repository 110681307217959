import { Box, Grid, ListItem, ListItemIcon, ListItemText, Typography, List } from "@mui/material"
import './Styles.css'
import logo from '../../Utils/Images/novetech.png'
import { AiFillMail, AiFillFacebook, AiFillLinkedin } from "react-icons/ai"

export const Footer = () => {
    return (
        <footer className='nvt-footer'>
            <Box className='container'>
                <Box className='row'>
                    <Grid container direction='row' spacing={4}>

                        <Grid item md={9}>
                            <Grid item md={8}>
                                <Box>
                                    <img src={logo} alt='logo de novatech' width={150} className="mb-3" />
                                    <Typography >
                                    Transformez votre vision en réalité numérique avec notre expertise. Contactez-nous pour commencer votre projet aujourd'hui.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid item md={3}>
                            <Box>
                                <Typography variant="subtitle1">Pour nous contacter</Typography>
                                <List>
                                    <ListItem>
                                        <ListItemIcon>
                                            <AiFillMail size={20} color="white" />
                                        </ListItemIcon>
                                        <ListItemText>commercial@novatech.mg</ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <AiFillFacebook size={20} color="white" />
                                        </ListItemIcon>
                                        <ListItemText>novatech</ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <AiFillLinkedin size={20} color="white" />
                                        </ListItemIcon>
                                        <ListItemText>novatech.mg</ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <AiFillLinkedin size={20} color="white" />
                                        </ListItemIcon>
                                        <ListItemText>Antananarivo-Madagascar</ListItemText>
                                    </ListItem>
                                    <ListItem >
                                        <ListItemText className="nvt-legales">Politique de confidentialite / Cgv / Mention legal</ListItemText>
                                    </ListItem>
                                </List>
                            </Box>
                        </Grid>

                    </Grid>
                </Box>
            </Box>
            <Box className='nvt-sub-footer d-flex justify-content-center align-content-center'>
                <Typography variant="body2">Novatech copyright 2024 - design by edwinot</Typography>
            </Box>
        </footer>
    )
}