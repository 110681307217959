import svc_entreprise from './../Utils/Images/services/entreprise.jpg'
import svc_blog from './../Utils/Images/services/blog.jpg'
import svc_commerce from './../Utils/Images/services/e-commerce.jpg'
import svc_institutionel from './../Utils/Images/services/institutionels.jpg'


export const images_sources = {
   services :{
    svc_entreprise,
    svc_blog,
    svc_commerce,
    svc_institutionel
   }
}