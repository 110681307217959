import { Box, Grid, Typography } from "@mui/material"
import { useContext, useRef, useState } from "react"
import { AiOutlineArrowLeft, AiTwotoneHome, AiOutlineArrowRight } from "react-icons/ai"
import './styles.css'
import { CgWebsite } from "react-icons/cg"
import { DataContext } from "../../Context/DataContext"
export const FaqComponent = () => {
    const RefBloc = useRef()
    const [hiddenArrow, setHiddenArrow] = useState(false)

    const { home } = useContext(DataContext)



    const handeClick = (event) => {
        let number = event.target.getAttribute('data-number')
        let response = document.getElementsByClassName('nvt-faq-bloc-response')

        document.getElementsByClassName('is-respons-active')[0].classList.remove('is-respons-active')
        document.getElementsByClassName('is-faq-active')[0].classList.remove('is-faq-active')
        event.target.classList.add('is-faq-active')
        response[number].classList.add('is-respons-active')

        setHiddenArrow(false)
    }

    const handelResponse = (event) => {

        if (document.querySelector('.response-is-visible') != null) {
            document.querySelector('.response-is-visible').classList.remove('response-is-visible')
        }

        event.target.parentNode.parentNode.classList.contains('response-is-visible') ?
            event.target.parentNode.parentNode.classList.remove('response-is-visible') :
            event.target.parentNode.parentNode.classList.add('response-is-visible')

        setHiddenArrow(prevent => (prevent = !hiddenArrow))
    }

    return (
        <Box component='section' className="nvt-faq-section container">
            <Typography variant='h4' className="nvt-title" data-aos='fade-up'>Questions Fréquemment Posées</Typography>
            <Grid container spacing={2} className="nvt-grid-questions">
                <Grid
                    item md='3'
                    data-aos='fade-right'
                >
                    <Box className='nvt-grid-fa-inner'>

                        {
                            home.Questions.map((item, key) => (
                                <Box className='nvt-position-icon' key={key}>
                                    <Box component='span'>
                                        <AiTwotoneHome size={20} />
                                    </Box>
                                    <Typography

                                        data-number={key}
                                        className={`nvt-fa-question ${key == 0 && 'is-faq-active'}`}
                                        onClick={(event) => handeClick(event)} >{item._parent._title}</Typography>
                                </Box>
                            ))
                        }
                    </Box>
                </Grid>
                <Grid
                    item md='9'
                    data-aos='fade-left'
                >
                    <Box className={`nvt-padding ${hiddenArrow ? 'nvt-hidden-all-div' : 'nvt-show-all-div'}`} ref={RefBloc}>

                        {
                            home.Questions.map((item, key) => {

                                return (
                                    <Box
                                        key={key}
                                        className={`nvt-faq-bloc-response ${key == 0 && 'is-respons-active'}`}>

                                        {
                                            item._parent._arr.map((arr, k) =>
                                                <Box className='nvt-select'>
                                                    <Box className='nvt-flex-bloc'>
                                                        {
                                                            hiddenArrow && (
                                                                <Box component='span' className="nvt-arrow-right">
                                                                    <AiOutlineArrowLeft color="#0bf0a3" size={20} />
                                                                </Box>
                                                            )
                                                        }
                                                        <Typography
                                                            className="nvt-view-response"
                                                            style={hiddenArrow ? { paddingLeft: '25px' } : { paddingRight: '0' }}
                                                            onClick={(event) => handelResponse(event)}
                                                        >{arr.Q}</Typography>

                                                        {
                                                            !hiddenArrow && (
                                                                <Box component='span' className="nvt-arrow-left">
                                                                    <AiOutlineArrowRight color="#0bf0a3" size={20} />
                                                                </Box>
                                                            )
                                                        }
                                                    </Box>
                                                    <Box className='nvt-visible-response-bloc nvt-hidden-bloc'>
                                                        {arr.R.split('.').map(item => {return (<>{item != ''&& <Typography variant="body2">{item}.</Typography>}</>)})}
                                                    </Box>
                                                </Box>
                                            )
                                        }
                                    </Box>
                                )
                            })
                        }
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}