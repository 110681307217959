

import {
    DiSymfonyBadge,
    DiCodeigniter,
    DiLaravel,
    DiNodejsSmall,
    DiPhp,
    DiAngularSimple,
    DiWordpress,
    DiReact,
    DiJavascript1
} from "react-icons/di"


import { images_sources } from "./image-source"

export const dbcontent = {
    home: {
        faq:
            [
                {
                    id: 1,
                    questions: "Combient coute un site web?",
                    response: "Les agences web proposent généralement une variété de services pour la création, la conception et le développement de sites web dans différents domaines. Voici quelques types de sites web que les agences web peuvent proposer à leurs clients Site web d'entreprise ou corporatif : Les agences web peuvent concevoir et développer des sites web pour les entreprises afin de présenter leurs produits, services et leur image de marque de manière professionnelle. Les agences web proposent généralement une variété de services pour la création, la conception et le développement de sites web dans différents domaines. Voici quelques types de sites web que les agences web peuvent proposer à leurs clients Site web d'entreprise ou corporatif : Les agences web peuvent concevoir et développer des sites web pour les entreprises afin de présenter leurs produits, services et leur image de marque de manière professionnelle. Les agences web proposent généralement une variété de services pour la création, la conception et le développement de sites web dans différents domaines. Voici quelques types de sites web que les agences web peuvent proposer à leurs clients Site web d'entreprise ou corporatif : Les agences web peuvent concevoir et développer des sites web pour les entreprises afin de présenter leurs produits, services et leur image de marque de manière professionnelle."
                },
                {
                    id: 2,
                    questions: "Combient coute un site web?",
                    response: "Les agences web proposent généralement une variété de services pour la création, la conception et le développement de sites web dans différents domaines. Voici quelques types de sites web que les agences web peuvent proposer à leurs clients Site web d'entreprise ou corporatif : Les agences web peuvent concevoir et développer des sites web pour les entreprises afin de présenter leurs produits, services et leur image de marque de manière professionnelle. Les agences web proposent généralement une variété de services pour la création, la conception et le développement de sites web dans différents domaines. Voici quelques types de sites web que les agences web peuvent proposer à leurs clients Site web d'entreprise ou corporatif : Les agences web peuvent concevoir et développer des sites web pour les entreprises afin de présenter leurs produits, services et leur image de marque de manière professionnelle. Les agences web proposent généralement une variété de services pour la création, la conception et le développement de sites web dans différents domaines. Voici quelques types de sites web que les agences web peuvent proposer à leurs clients Site web d'entreprise ou corporatif : Les agences web peuvent concevoir et développer des sites web pour les entreprises afin de présenter leurs produits, services et leur image de marque de manière professionnelle."
                },
                {
                    id: 3,
                    questions: "Combient coute un site web?",
                    response: "Les agences web proposent généralement une variété de services pour la création, la conception et le développement de sites web dans différents domaines. Voici quelques types de sites web que les agences web peuvent proposer à leurs clients Site web d'entreprise ou corporatif : Les agences web peuvent concevoir et développer des sites web pour les entreprises afin de présenter leurs produits, services et leur image de marque de manière professionnelle. Les agences web proposent généralement une variété de services pour la création, la conception et le développement de sites web dans différents domaines. Voici quelques types de sites web que les agences web peuvent proposer à leurs clients Site web d'entreprise ou corporatif : Les agences web peuvent concevoir et développer des sites web pour les entreprises afin de présenter leurs produits, services et leur image de marque de manière professionnelle. Les agences web proposent généralement une variété de services pour la création, la conception et le développement de sites web dans différents domaines. Voici quelques types de sites web que les agences web peuvent proposer à leurs clients Site web d'entreprise ou corporatif : Les agences web peuvent concevoir et développer des sites web pour les entreprises afin de présenter leurs produits, services et leur image de marque de manière professionnelle."
                },
                {
                    id: 4,
                    questions: "Combient coute un site web?",
                    response: "Les agences web proposent généralement une variété de services pour la création, la conception et le développement de sites web dans différents domaines. Voici quelques types de sites web que les agences web peuvent proposer à leurs clients Site web d'entreprise ou corporatif : Les agences web peuvent concevoir et développer des sites web pour les entreprises afin de présenter leurs produits, services et leur image de marque de manière professionnelle. Les agences web proposent généralement une variété de services pour la création, la conception et le développement de sites web dans différents domaines. Voici quelques types de sites web que les agences web peuvent proposer à leurs clients Site web d'entreprise ou corporatif : Les agences web peuvent concevoir et développer des sites web pour les entreprises afin de présenter leurs produits, services et leur image de marque de manière professionnelle. Les agences web proposent généralement une variété de services pour la création, la conception et le développement de sites web dans différents domaines. Voici quelques types de sites web que les agences web peuvent proposer à leurs clients Site web d'entreprise ou corporatif : Les agences web peuvent concevoir et développer des sites web pour les entreprises afin de présenter leurs produits, services et leur image de marque de manière professionnelle."
                },
                {
                    id: 5,
                    questions: "Combient coute un site web?",
                    response: "Les agences web proposent généralement une variété de services pour la création, la conception et le développement de sites web dans différents domaines. Voici quelques types de sites web que les agences web peuvent proposer à leurs clients Site web d'entreprise ou corporatif : Les agences web peuvent concevoir et développer des sites web pour les entreprises afin de présenter leurs produits, services et leur image de marque de manière professionnelle. Les agences web proposent généralement une variété de services pour la création, la conception et le développement de sites web dans différents domaines. Voici quelques types de sites web que les agences web peuvent proposer à leurs clients Site web d'entreprise ou corporatif : Les agences web peuvent concevoir et développer des sites web pour les entreprises afin de présenter leurs produits, services et leur image de marque de manière professionnelle. Les agences web proposent généralement une variété de services pour la création, la conception et le développement de sites web dans différents domaines. Voici quelques types de sites web que les agences web peuvent proposer à leurs clients Site web d'entreprise ou corporatif : Les agences web peuvent concevoir et développer des sites web pour les entreprises afin de présenter leurs produits, services et leur image de marque de manière professionnelle."
                },
                {
                    id: 6,
                    questions: "Combient coute un site web?",
                    response: "Les agences web proposent généralement une variété de services pour la création, la conception et le développement de sites web dans différents domaines. Voici quelques types de sites web que les agences web peuvent proposer à leurs clients Site web d'entreprise ou corporatif : Les agences web peuvent concevoir et développer des sites web pour les entreprises afin de présenter leurs produits, services et leur image de marque de manière professionnelle. Les agences web proposent généralement une variété de services pour la création, la conception et le développement de sites web dans différents domaines. Voici quelques types de sites web que les agences web peuvent proposer à leurs clients Site web d'entreprise ou corporatif : Les agences web peuvent concevoir et développer des sites web pour les entreprises afin de présenter leurs produits, services et leur image de marque de manière professionnelle. Les agences web proposent généralement une variété de services pour la création, la conception et le développement de sites web dans différents domaines. Voici quelques types de sites web que les agences web peuvent proposer à leurs clients Site web d'entreprise ou corporatif : Les agences web peuvent concevoir et développer des sites web pour les entreprises afin de présenter leurs produits, services et leur image de marque de manière professionnelle."
                }
            ],
        splider: [
            {
                title: "Site d'Entreprise",
                content: "Découvrez notre expertise en création de sites web d'entreprise qui transforment notre vision en réalité numérique. Avec une conception intuitive et des fonctionnalités innovantes, nous vous aidons à projeter une image professionnelle, captivante et adaptée à vos besoins spécifiques. Explorez comment notre approche personnalisée peut renforcer notre présence en ligne et vous démarquer dans notre secteur.",
                src_img: images_sources.services.svc_entreprise
            },

        ],






        Questions: [
            {
                _parent: {
                    _title: 'À Propos de Notre Agence',
                    _arr:
                        [
                            {
                                Q: 'Quelle est notre politique en matière de révisions et de modifications pendant le développement du site ?',
                                R: 'Nous offrons plusieurs rondes de révisions gratuites à chaque étape du développement pour garantir que le site correspond parfaitement à vos attentes. Si des modifications supplémentaires sont nécessaires après les révisions incluses, nous établirons un devis détaillé pour notre approbation avant de procéder. Notre objectif est de maintenir une communication transparente et efficace tout au long du processus.'
                            },
                            {
                                Q: 'Quelle est notre approche en matière de gestion de projet et de communication avec les clients ?',
                                R: "Notre approche en gestion de projet met l'accent sur une communication ouverte et régulière avec nos clients. Nous utilisons des outils collaboratifs pour suivre et partager les progrès, et nous organisons des réunions régulières pour aligner les objectifs et résoudre les problèmes rapidement. Notre objectif est de maintenir une transparence totale tout au long du processus de développement du projet."
                            },
                            {
                                Q: 'Quels types de services complémentaires offrez-vous en plus de la création de sites web ?',
                                R: "En plus de la création de sites web, nous proposons une gamme de services complémentaires incluant le marketing digital, le référencement (SEO), la gestion de contenu, le design graphique, et la maintenance continue. Ces services sont conçus pour renforcer notre présence en ligne et maximiser l'impact de notre site web."
                            },
                            {
                                Q: 'Comment assurez-vous la qualité et la satisfaction des clients pour chaque projet que vous réalisez ?',
                                R: "En mettant en place des procédures de contrôle qualité rigoureuses à chaque étape du projet, nous garantissons la qualité et la satisfaction des clients. Nous prenons soin de prendre en compte vos besoins, nous communiquons de manière claire tout au long du processus, et nous nous engageons à surpasser vos attentes en offrant des résultats précis, novateurs et adaptés à vos besoins particuliers."
                            },
                            {
                                Q: "Comment gérez-vous les projets à distance ou avec des clients internationaux ?",
                                R: "Afin de gérer les projets à distance ou avec des clients internationaux de manière efficace, nous employons des outils de collaboration en ligne de pointe pour faciliter la communication et le suivi des projets. Nous prévoyons méticuleusement les variations horaires et nous garantissons une communication claire et régulière grâce à des réunions en ligne et des mises à jour régulières. Nous visons à assurer une coordination performante tout en respectant les exigences particulières de chaque client, peu importe leur localisation géographique."
                            }
                        ]
                },
            },
            {
                _parent: {
                    _title: 'Processus de Développement',
                    _arr:
                        [
                            {
                                Q: 'Quelles sont les étapes principales du processus de développement de mon site web ?',
                                R: "Les étapes principales de développement de notre site web incluent l'analyse des besoins, la planification et la conception, le développement, les tests et validation, le lancement et le déploiement, ainsi que la maintenance continue pour assurer la performance et la sécurité à long terme."
                            },
                            {
                                Q: 'Quelle est la durée estimée pour le développement de mon site web ?',
                                R: "La durée estimée pour le développement de notre site web dépend de la complexité du projet, des fonctionnalités requises, et de la rapidité des révisions. Nous établissons un calendrier précis au début du projet, habituellement entre quelques semaines à plusieurs mois, pour vous fournir une estimation réaliste en fonction de vos besoins spécifiques."
                            },
                            {
                                Q: 'Comment sera assurée la communication tout au long du processus de développement ?',
                                R: "Nous assurons une communication fluide tout au long du processus de développement en utilisant des outils modernes de collaboration et en planifiant régulièrement des réunions pour discuter des progrès, des ajustements nécessaires et des mises à jour importantes. Notre objectif est de garantir que vous êtes constamment informé et impliqué dans chaque étape du projet."
                            },
                            {
                                Q: 'Est-ce que je pourrai voir des maquettes ou des prototypes de mon site web avant la phase finale ?',
                                R: "Oui, vous aurez l'opportunité de visualiser des maquettes ou des prototypes de notre site web avant la phase finale. Ces étapes intermédiaires sont cruciales pour recueillir vos feedbacks et ajuster le design et les fonctionnalités selon vos préférences et besoins spécifiques."
                            },
                            {
                                Q: "Quelles sont vos pratiques en matière de tests et d'assurance qualité pour mon site web ?",
                                R: "Nous mettons en œuvre des méthodes de test et d'assurance qualité rigoureuses afin d'assurer la performance et la fiabilité de notre site internet. Cela englobe des tests de fonctionnalité pour chaque fonctionnalité, des tests de compatibilité sur divers navigateurs et appareils, ainsi que des tests de sécurité afin de garantir la protection contre les vulnérabilités. Nous visons à fournir un site web solide et qui répond à vos attentes."
                            }
                        ]
                },
            },
            {
                _parent: {
                    _title: 'Tarifs et Budget',
                    _arr:
                        [
                            {
                                Q: "Quels sont les différents forfaits ou options disponibles pour la création de mon site web et qu'incluent-ils ?",
                                R: "Nous offrons diverses options sur mesure pour concevoir notre site web, en fonction de vos besoins particuliers. Chaque package diffère en ce qui concerne les caractéristiques, le design, l'hébergement, le support technique et les services supplémentaires tels que le référencement et la maintenance. Nous proposons des choix souples afin de satisfaire parfaitement vos objectifs et notre budget."
                            },
                            {
                                Q: "Quels sont les coûts initiaux et récurrents associés à la création et à l'entretien de mon site web ?",
                                R: "La création de notre site web nécessite des dépenses initiales, telles que le développement initial, le design, l'achat éventuel de domaines et d'autres frais de démarrage. L'hébergement, la maintenance, les mises à jour de sécurité et de contenu, ainsi que les frais constants pour le domaine et d'autres services supplémentaires tels que le marketing numérique ou le support client, sont des coûts récurrents. La taille et la complexité de notre site web ainsi que les services supplémentaires sélectionnés peuvent influencer ces coûts."
                            },
                            {
                                Q: 'Offrez-vous des devis personnalisés en fonction de mes besoins spécifiques ?',
                                R: "En effet, nous offrons des devis sur mesure en fonction de vos exigences particulières pour la conception de notre site web. Nous examinons de manière approfondie vos besoins et vos objectifs afin de vous donner une estimation précise qui reflète les fonctionnalités, le design et les services supplémentaires requis pour atteindre vos objectifs sur Internet."
                            },
                            {
                                Q: 'Y a-t-il des coûts supplémentaires à prévoir, comme des frais de licence pour des logiciels ou des plugins spécifiques ?',
                                R: "En effet, il est possible que des frais supplémentaires soient nécessaires pour l'acquisition de licences de logiciels ou de plugins spécifiques requis pour notre projet de site web. Ces dépenses varient en fonction des fonctionnalités et des technologies particulières que vous optez pour incorporer à notre site. Dès le début du projet, nous vous donnerons une estimation précise et claire de ces frais supplémentaires, pour que vous puissiez vous préparer en conséquence."
                            },
                            {
                                Q: "Quelles sont les options de paiement disponibles et quelles sont vos conditions de paiement ?",
                                R: "Nous proposons plusieurs options de paiement flexibles pour faciliter la création de notre site web. Les méthodes de paiement habituelles incluent les virements bancaires, les chèques, les paiements par carte de crédit ou par PayPal. Nos conditions de paiement varient selon le projet, mais elles incluent généralement un acompte initial au début du projet, suivi de paiements échelonnés tout au long du développement, avec un solde final dû à la livraison du site web."
                            }
                        ]
                },
            },
            {
                _parent: {
                    _title: 'Personnalisation et Fonctionnalités',
                    _arr:
                        [
                            {
                                Q: "Pouvez-vous personnaliser le design de mon site web pour qu'il reflète l'identité de ma marque ou de mon entreprise ?",
                                R: ''
                            },
                            {
                                Q: "Quelles fonctionnalités spécifiques pouvez-vous intégrer à mon site web, comme une boutique en ligne, des formulaires de contact avancés, ou des galeries d'images ?",
                                R: "Nous pouvons intégrer diverses fonctionnalités spécifiques à votre site web, notamment une boutique en ligne complète avec paiement sécurisé, des formulaires de contact avancés pour une meilleure interaction avec vos visiteurs, et des galeries d'images élégantes pour présenter vos produits ou services. De plus, nous offrons des fonctionnalités personnalisées telles que des blogs, des systèmes de réservation en ligne, et des intégrations de réseaux sociaux pour répondre à vos besoins uniques."
                            },
                            {
                                Q: 'Est-ce que je peux ajouter du contenu moi-même une fois que le site est lancé, et si oui, comment ?',
                                R: "Oui, vous pouvez ajouter du contenu vous-même une fois que le site est lancé. Nous utilisons des systèmes de gestion de contenu (CMS) intuitifs comme WordPress, Joomla, ou Drupal, qui vous permettent de facilement mettre à jour votre site. Nous vous fournirons une formation et des guides pour vous aider à utiliser ces outils efficacement."
                            },
                            {
                                Q: "Offrez-vous des solutions personnalisées pour des besoins spécifiques, comme la localisation multilingue ?",
                                R: "Oui, nous offrons des solutions personnalisées pour répondre à des besoins spécifiques, y compris la localisation multilingue. Nous adaptons votre site pour qu'il soit accessible dans plusieurs langues, permettant ainsi de toucher un public plus large et diversifié. Notre approche sur mesure garantit que chaque aspect de votre site répond parfaitement à vos exigences uniques."
                            },
                            {
                                Q: "Comment assurez-vous la compatibilité et la convivialité mobile de mon site web ?",
                                R: "Nous assurons la compatibilité et la convivialité mobile de votre site web en utilisant un design responsive, qui adapte automatiquement l'affichage aux différentes tailles d'écran. Nous effectuons des tests rigoureux sur divers appareils et navigateurs pour garantir une expérience utilisateur fluide et cohérente. De plus, nous optimisons les performances mobiles pour des temps de chargement rapides et une navigation intuitive."
                            }
                        ],
                },
            },
        ],
        technologies: {
            title: 'nos technologies',
            content: [
                {
                    title: 'Javascript',
                    icons: <DiJavascript1 size={100} color="white" />,
                    description: "JavaScript est utilisé pour rendre les sites web interactifs."
                },
                {
                    title: 'ReactJs',
                    icons: <DiReact size={100} color="white" />,
                    description: "ReactJS est une bibliothèque JavaScript pour créer des interfaces utilisateur interactives."
                },
                {
                    title: 'Angular',
                    icons: <DiAngularSimple size={100} color="white" />,
                    description: "Angular est un framework JavaScript pour créer des applications web dynamiques."
                },
                {
                    title: 'NodeJs',
                    icons: <DiNodejsSmall size={100} color="white" />,
                    description: "Node.js est une plateforme de développement JavaScript côté serveur."
                },
                {
                    title: 'Php',
                    icons: <DiPhp size={100} color="white" />,
                    description: "Langage utilisé pour créer des pages web dynamiques côté serveur."
                },
                {
                    title: 'Wordpress',
                    icons: <DiWordpress size={100} color="white" />,
                    description: "WordPress est une plateforme open-source pour créer et gérer des sites web."
                },
                {
                    title: 'Symfony',
                    icons: <DiSymfonyBadge size={100} color="white" />,
                    description: "Symfony est un framework PHP pour développer des applications web."
                },
                {
                    title: 'Codeigniter',
                    icons: <DiCodeigniter size={100} color="white" />,
                    description: "Framework PHP léger et puissant pour le développement rapide d'applications web."
                },
                {
                    title: 'Laravel',
                    icons: <DiLaravel size={100} color="white" />,
                    description: "Framework PHP moderne et puissant pour le développement d'applications web."
                }
            ]
        },
        avantages: [
            {
                title: "Professionnalisme et Esthétique séduisante",
                content: "Nous nous engageons à allier professionnalisme et esthétique captivante à chaque étape de notre travail, de la conception à l'exécution. Notre objectif est de créer des solutions efficaces qui séduisent visuellement nos clients."
            },
            {
                title: "Fonctionnalités et Simplicité d'utilisation",
                content: "Nos produits sont conçus avec l'utilisateur final à l'esprit. Chaque interaction avec nos solutions est intuitive et fluide, garantissant une expérience utilisateur optimale."
            },
            {
                title: "Optimisation pour les Moteurs de Recherche",
                content: "Nous optimisons votre visibilité sur les moteurs de recherche avec des techniques avancées. Notre approche assure un contenu accessible et bien positionné, attirant un trafic qualifié vers votre site."
            },
            {
                title: "Support et Service Client de Qualité",
                content: "Nous offrons un service client exceptionnel avec une équipe dédiée disponible pour répondre à vos besoins rapidement et efficacement, vous accompagnant à chaque étape de votre expérience avec nous."
            }
        ]
    }
}

